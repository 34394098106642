/**
Name: footer.scss
Description: Footer section styles
*/

.footer {
  padding-top: 1rem;
}

.footer-disclaimer {
  font-size: .8em;
  text-align: center;
  margin-top: 1rem;
}

.footer-disclaimer-link,
.footer-disclaimer-link:visited {
  color: var(--main-text-color);
  text-decoration: none;
  font-weight: 600;
}

.footer-disclaimer-link:hover {
  text-decoration: underline;
}
