/**
Name: archive.scss
Description: Archives section styles
*/

.archives-complement {
  margin-left: .5em;
  font-size: .8em;
  color: var(--main-text-color);
}

.archives-link a:link,
.archives-link a:hover {
  color: var(--archives-link-color);
}

.archives-link a:visited {
  color: var(--archives-link-visited-color);
}
