/**
Name: author.scss
Description: Author section styles
*/

/* @mixin social-background-icon($url) {
  background: url($url) no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
} */

.avatar {
  border-radius: 100%;
}

.avatar--size {
  width: 6em;
  height: 6em;
}

.page-author-avatar {
  text-align: center;
}

.authors {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 4rem;
  margin: 2em 0 4rem;
}

.author__links {
  margin-top: .5em;
  margin-bottom: .5em;
}

.author__links a {
  text-decoration: none;
}

.author__name {
  font-size: 1.2em;
}

.author__bio {
  margin-bottom: 1em;
  padding-bottom: 1em;
  width: 15em;
  border-bottom: .06em solid var(--post-separator-color);
}

.author__posts-count {
  color: var(--author-post-count-color);
  font-weight: 600;
}

.social {
  /* On small screens, we are no longer using row direction but column */
  flex-direction: column;
  justify-content: flex-start;
}

.social-item {
  margin-right: auto;
}

.social-item .social-web {
  background: url("/images/icon-web.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}


.social-twitter {
  background: url("/images/twitter.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-linkedin {
  background: url("/images/linkedin.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-github {
  background: url("/images/github.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-facebook {
  background: url("/images/facebook.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-gitlab {
  background: url("/images/gitlab.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-mastodon {
  background: url("/images/mastodon.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-slack {
  background: url("/images/slack.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-instagram {
  background: url("/images/instagram.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

.social-devto {
  background: url("/images/rainbowdev.svg") no-repeat left center,
    linear-gradient(transparent, transparent);
  background-size: auto 1.5em;
  display: block;
  padding: .5em 0 .3em 2em;
}

@media all and (min-width: 47em) {

  .avatar--size {
    width: 8em;
    height: 8em;
  }

  .page-author-avatar {
    text-align: left;
  }

  .social {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .social-item {
    margin-right: 0;
  }

}
